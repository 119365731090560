@mixin hardware-accel() {
  // Improve performance on mobile/tablet devices
  // Perspective reduces blurryness of text in Chrome
  transform: perspective(1px) translateZ(0);
}

@mixin improve-anti-alias() {
  // Improve aliasing on mobile/tablet devices
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

@mixin hacks() {
  @include hardware-accel();
  @include improve-anti-alias();
}

@mixin overline-from-center {
  $duration: $fast-duration;

  @include hacks();
  position: relative;
  //overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    top: -9px;
    background: $white;
    height: 2.5px;
    transition-property: "left, right";
    transition-duration: $duration;
    transition-timing-function: ease-out;
  }

  &:hover,
  &:focus,
  &:active {
    &::before {
      left: 0;
      right: 0;
    }
  }
}

@mixin link-hover {
  @include overline-from-center;
}

@mixin button-hover {
  @include overline-from-center;
  &::before {
    height: 5px;
    top: -24px;
    background: $blue-light;
  }
}

@mixin shift-forward($selector) {
  #{$selector} {
    transform: translateZ(0);
    transition-duration: $fast-duration;
    transition-property: transform;
    transition-timing-function: ease-out;
  }

  &:hover,
  &:focus,
  &:active {
    #{$selector} {
      transform: translateX(4px);
    }
  }
}

@mixin opacity-dim($opacity: 0.8, $selector: null) {
  @if ($selector != null) {
    #{$selector} {
      transition-duration: $fast-duration;
      transition-property: opacity;
      transition-timing-function: ease-out;
    }

    &:hover,
    &:focus,
    &:active {
      #{$selector} {
        opacity: $opacity;
      }
    }
  } @else {
    transition-duration: $fast-duration;
    transition-property: opacity;
    transition-timing-function: ease-out;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.7;
    }
  }
}
