@mixin chevron-link($hover-element: null, $color: $carbon) {
  @include link-text;
  display: inline-block;
  color: $color;
  position: relative;
  margin-left: -15px;
  $chevron-link: &;

  $icon: "../DocumentDefault/Assets/chevron.svg";
  @if $color == $white {
    $icon: "../DocumentDefault/Assets/chevron-white.svg";
  }

  &::after {
    background-image: url($icon);
    width: 6px;
    height: 11px;
    display: inline-block;
    content: " ";
    position: absolute;
    top: 2px;
    right: -18px;
  }

  &::before {
    opacity: 0;
    width: 100%;
    height: 1px;
    display: block;
    content: " ";
    background-color: $color;
    position: absolute;
    left: 0;
    bottom: 3px;
    transition: opacity $fast-duration ease-in-out;
  }

  &:hover::before {
    opacity: 1;
  }

  @if $hover-element != null {
    @at-root {
      #{$hover-element}:hover {
        #{$chevron-link} {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}

@mixin chevron-link-back($hover-element: null, $color: $carbon) {
  @include link-text;
  display: inline-block;
  color: $color;
  position: relative;
  margin-left: 15px;
  $chevron-link: &;

  $icon: "../DocumentDefault/Assets/chevron.svg";
  @if $color == $white {
    $icon: "../DocumentDefault/Assets/chevron-white.svg";
  }

  &::after {
    background-image: url($icon);
    width: 6px;
    height: 11px;
    display: inline-block;
    content: " ";
    position: absolute;
    top: 2px;
    left: -15px;
    transform: rotate(180deg);
  }

  &::before {
    opacity: 0;
    width: 100%;
    height: 1px;
    display: block;
    content: " ";
    background-color: $color;
    position: absolute;
    left: 0;
    bottom: 3px;
    transition: opacity $fast-duration ease-in-out;
  }

  &:hover::before {
    opacity: 1;
  }

  @if $hover-element != null {
    @at-root {
      #{$hover-element}:hover {
        #{$chevron-link} {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}

@mixin text-input() {
  @include body-text;

  border: none;
  outline: none;
  background-color: $white;
  color: $aluminium;
  padding: 14px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  // padding-right: 74px;

  &::placeholder {
    @include body-text;
    color: $shale;
  }
}

@mixin search-form() {
  position: relative;
  max-width: 640px;
  margin: 0 auto;

  &__close {
    display: none;
  }

  input[type="text"] {
    @include text-input;
  }

  button[type="submit"] {
    cursor: pointer;
    position: absolute;
    height: 60px;
    width: 60px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    font-size: rem-calc(20);
    background-color: $red;
    right: 0;
    top: 0;

    i {
      color: $white;
    }
  }
}

@mixin form-autocomplete() {
  input {
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    border: 1px solid #ddd;
  }

  .facetwp-autocomplete {
    width: 100%;
    height: 60px;
    margin-top: 8px;
    padding: 0 16px;
    font-family: $legacy-book;

    &::placeholder {
      color: #cecece;
    }

    &:focus {
      border: 1px solid $red;
      outline: none;
    }

    &-update {
      display: none;
    }
  }

  .autocomplete-suggestions {
    border: 1px solid $slate !important; // plugin styles override
    box-shadow: none !important; // plugin styles override
  }

  .autocomplete-suggestion {
    padding: 23px 16px !important; // plugin styles override
  }
}

@mixin form-checkbox-check($color: $red) {
  -moz-appearance: none;
  -webkit-appearance: none;
  display: block;
  content: " ";
  position: relative;
  width: rem-calc(14);
  min-width: rem-calc(14);
  height: rem-calc(14);
  outline: 2px solid $black;
  border: 2px solid $white;
  margin-right: rem-calc(11);
  transition: outline $fast-duration ease, background-color $fast-duration ease;
}

@mixin form-checkbox-check-active($color: $red) {
  border: 2px solid $white;
  background-color: $color;
}

@mixin form-checkbox($color: $red) {
  background: none;
  padding-left: 0;
  display: flex;
  transition: color $fast-duration ease;

  + label {
    transition: color $fast-duration ease;
  }

  &::before {
    @include form-checkbox-check;
  }

  &.checked,
  &.nf-checked {
    background: none;
    color: $color;

    + label {
      color: $color;
    }

    &::before {
      outline: 2px solid $color;
      background-color: $color;
    }
  }
}

@mixin form-tabs() {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $shale;
}

@mixin form-tab($color: $blue-light) {
  line-height: 45px;
  width: 100%;
  cursor: pointer;
  position: relative;
  transition: color $fast-duration ease-in-out;
  color: $carbon;
  padding: 0 16px;

  @include bp(md) {
    padding: 12px;
    text-align: center;
    background-color: $white;
  }

  &:hover {
    color: $color !important; // plugin styles override
  }

  &.checked {
    font-weight: bold;
    color: $color !important; // plugin styles override
    @include bp(md) {
      &::after {
        position: absolute;
        width: 100%;
        height: 4px;
        background: $color;
        bottom: -3px;
        left: 0;
        content: " ";
      }
    }
  }
}
